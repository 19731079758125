import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import PageLinks from "../components/page/links"
import ProductLinks from "../components/product/links"

// V1

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>Hi people</h1>

    <h2>Page Links</h2>
    <PageLinks />

    <h2>Product Links</h2>
    <ProductLinks />

    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <StaticImage
      src="../assets/images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
  </Layout>
)

export default IndexPage
