import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

const PageLinks = (props) => {

  const data = useStaticQuery(graphql`
    query {
      allBigCommercePages {
        edges {
          node {
            id
            url
            name
            is_visible
          }
        }
      }
    }
  `)

  return (
    <ul>
      {data.allBigCommercePages.edges.map((edge, index) => {
        if (!edge.node.is_visible) return null;

        return (
          <li key={edge.node.id}>
            <Link to={edge.node.url}>{edge.node.name}</Link>
          </li>
        )
      })}
    </ul>
  )
}

export default PageLinks;