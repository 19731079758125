import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

const ProductLinks = (props) => {

  const data = useStaticQuery(graphql`
    query {
      allBigCommerceProducts {
        edges {
          node {
            id
            name
            is_visible
            custom_url {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <ul>
      {data.allBigCommerceProducts.edges.map((edge, index) => {
        if (!edge.node.is_visible) return null;

        return (
          <li key={edge.node.id}>
            <Link to={edge.node.custom_url.url}>{edge.node.name}</Link>
          </li>
        )
      })}
    </ul>
  )
}

export default ProductLinks;